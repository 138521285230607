<template>
  <div class="app-main-content">
    <div class="container-left">
      <div class="container-left-header">
        <div class="title">分配给我的</div>
        <div class="content">
          <div class="item">
            <img :src="$toUrl('task_status_blue1.png')" alt="">
            <div class="info">
              <div class="status">未开始</div>
              <div class="value">{{ staData.not_start_tome }}</div>
            </div>
          </div>
          <div class="item">
            <img :src="$toUrl('task_status_yellow1.png')" alt="">
            <div class="info">
              <div class="status">进行中</div>
              <div class="value">{{ staData.start_tome }}</div>
            </div>
          </div>
          <div class="item">
            <img :src="$toUrl('task_status_green1.png')" alt="">
            <div class="info">
              <div class="status">已完成</div>
              <div class="value">{{ staData.finish_tome }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-left-content">
        <card :tabs="issue_status" :allow="false" v-model="allotQuery.issue_status"
            @change="getAllotList"  body-class="overflow-hidden page-card-body">
          <div class="body-flex">
            <div class="body-left-table">
              <a-table
                  :data-source="allotList"
                  rowKey="oid"
                  :pagination="false"
                  :customRow="customRow"
              >
                <a-table-column title="ID" data-index="seqid" width="70px"></a-table-column>
                <a-table-column title="标题"  data-index="issue_name">
                  <template slot-scope="scope, row">
                    <template v-if="row.issue_type === 'R'">
                      <!--                  需求-->
                      <img class="icon" v-if="row.is_later" :src="$toUrl('req_later.png')">
                      <img class="icon" v-else-if="row.issue_status === '0'" :src="$toUrl('req.png')">
                      <img class="icon" v-else-if="row.issue_status === '1'" :src="$toUrl('req_progress.png')">
                      <img class="icon" v-else-if="row.issue_status === '100'" :src="$toUrl('req_complete.png')">
                    </template>
                    <template v-if="row.issue_type === 'T'">
                      <!--                  任务-->
                      <img class="icon" v-if="row.is_later === '1' || row.issue_status === '10'" :src="$toUrl('task_red.png')" alt="">
                      <img class="icon" v-else-if="row.issue_status === '0'" :src="$toUrl('task_blue.png')" alt="">
                      <img class="icon" v-else-if="row.issue_status === '1'" :src="$toUrl('task_yellow.png')" alt="">
                      <img class="icon" v-else-if="row.issue_status === '100'" :src="$toUrl('task_green.png')" alt="">
                    </template>
                    <template v-if="row.issue_type === 'M'">
                      <!--                  里程碑-->
                      <img class="icon" :src="`${appsettings.imgPrefix}milepost_blue.png`">
                    </template>
                    <template v-if="row.issue_type === 'F'">父节点</template>
                    <template v-if="row.issue_type === 'Q'">缺陷</template>
                    <span style="margin-left: 5px;vertical-align: middle;">{{ row.issue_name }}</span>
                    <!-- <a-input ref="table-name" v-if="editName" v-model="row.project_name" @blur="fieldUpdate(row.project_name)"></a-input>
                      <i v-if="!editName" class="iconfont icon-wenbenshuru" @click="clickFoucs"></i> -->
                  </template>
                </a-table-column>
                <a-table-column title="项目名称" data-index="project_name" width="200px"></a-table-column>
                <a-table-column title="阶段名称" data-index="phase_name" width="140px"></a-table-column>
                <a-table-column title="优先级" data-index="age" width="90px" align="center">
                  <template v-slot:default="_, row">
                    <div v-if="row.issue_level_name" class="edp-tag "
                         :class="{
                      green: row.issue_level === '100',
                      yellow: row.issue_level === '300',
                      blue: row.issue_level === '200',
                      red: row.issue_level === '400',
                    }">
                      {{ row.issue_level_name }}
                    </div>
                  </template>
                </a-table-column>
                <a-table-column title="状态" data-index="" width="70px" align="center">
                  <template v-slot:default="_, row">
                    <div
                        class="edp-status"
                        :class="{
                    blue: row.issue_status === '0',
                    yellow: row.issue_status === '1',
                    green: row.issue_status === '100',
                    gray: row.issue_status === '1000',
                  }">
                      {{ row.issue_status_name }}
                    </div>
                  </template>
                </a-table-column>
                <a-table-column title="计划结束" data-index="schedule_end_date" width="110px"></a-table-column>
              </a-table>
            </div>
          </div>
        </card>
      </div>
    </div>
    <div class="container-right">
      <div class="container-right-header">
        <div class="title">我创建的</div>
        <div class="content">
          <div class="item">
            <img :src="$toUrl('task_status_blue1.png')" alt="">
            <div class="info">
              <div class="status">未开始</div>
              <div class="value">{{ staData.not_start_icreated }}</div>
            </div>
          </div>
          <div class="item">
            <img :src="$toUrl('task_status_yellow1.png')" alt="">
            <div class="info">
              <div class="status">进行中</div>
              <div class="value">{{ staData.start_icreated }}</div>
            </div>
          </div>
          <div class="item">
            <img :src="$toUrl('task_status_green1.png')" alt="">
            <div class="info">
              <div class="status">已完成</div>
              <div class="value">{{ staData.finish_icreated }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-right-content">
        <card :tabs="issue_status" :allow="false" v-model="ICreateQuery.issue_status"
              @change="getICreateList"  body-class="overflow-hidden page-card-body">
          <div class="body-flex">
            <div class="body-left-table">
              <a-table
                  :data-source="ICreateList"
                  rowKey="oid"
                  :pagination="false"
                  :customRow="customRow"
              >
                <a-table-column title="ID" data-index="seqid" width="70px"></a-table-column>
                <a-table-column title="标题"  data-index="issue_name">
                  <template slot-scope="scope, row">
                    <template v-if="row.issue_type === 'R'">
                      <!--                  需求-->
                      <img class="icon" v-if="row.is_later" :src="$toUrl('req_later.png')">
                      <img class="icon" v-else-if="row.issue_status === '0'" :src="$toUrl('req.png')">
                      <img class="icon" v-else-if="row.issue_status === '1'" :src="$toUrl('req_progress.png')">
                      <img class="icon" v-else-if="row.issue_status === '100'" :src="$toUrl('req_complete.png')">
                    </template>
                    <template v-if="row.issue_type === 'T'">
                      <!--                  任务-->
                      <img class="icon" v-if="row.is_later === '1' || row.issue_status === '10'" :src="$toUrl('task_red.png')" alt="">
                      <img class="icon" v-else-if="row.issue_status === '0'" :src="$toUrl('task_blue.png')" alt="">
                      <img class="icon" v-else-if="row.issue_status === '1'" :src="$toUrl('task_yellow.png')" alt="">
                      <img class="icon" v-else-if="row.issue_status === '100'" :src="$toUrl('task_green.png')" alt="">
                    </template>
                    <template v-if="row.issue_type === 'M'">
                      <!--                  里程碑-->
                      <img class="icon" :src="`${appsettings.imgPrefix}milepost_blue.png`">
                    </template>
                    <template v-if="row.issue_type === 'F'">父节点</template>
                    <template v-if="row.issue_type === 'Q'">缺陷</template>
                    <span style="margin-left: 5px;vertical-align: middle;">{{ row.issue_name }}</span>
                    <!-- <a-input ref="table-name" v-if="editName" v-model="row.project_name" @blur="fieldUpdate(row.project_name)"></a-input>
                      <i v-if="!editName" class="iconfont icon-wenbenshuru" @click="clickFoucs"></i> -->
                  </template>
                </a-table-column>
                <a-table-column title="项目名称" data-index="project_name" width="200px"></a-table-column>
                <a-table-column title="阶段名称" data-index="phase_name" width="140px"></a-table-column>
                <a-table-column title="优先级" data-index="age" width="90px" align="center">
                  <template v-slot:default="_, row">
                    <div v-if="row.issue_level_name" class="edp-tag "
                         :class="{
                      green: row.issue_level === '100',
                      yellow: row.issue_level === '300',
                      blue: row.issue_level === '200',
                      red: row.issue_level === '400',
                    }">
                      {{ row.issue_level_name }}
                    </div>
                  </template>
                </a-table-column>
                <a-table-column title="状态" data-index="" width="70px" align="center">
                  <template v-slot:default="_, row">
                    <div
                        class="edp-status"
                        :class="{
                    blue: row.issue_status === '0',
                    yellow: row.issue_status === '1',
                    green: row.issue_status === '100',
                    gray: row.issue_status === '1000',
                  }">
                      {{ row.issue_status_name }}
                    </div>
                  </template>
                </a-table-column>
                <a-table-column title="计划结束" data-index="schedule_end_date" width="110px"></a-table-column>
              </a-table>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {fetch} from '@/utils/request'
import appsettings from '@/utils/appsettings'
import catMixin from "@/utils/mixins/catMixin";

export default {
  components: {},
  mixins: [catMixin],
  data() {
    return {
      appsettings: appsettings,
      cat: {
        issue_bstatus: []
      },
      staData: {
        not_start_tome: 0,
        start_tome: 0,
        finish_tome: 0,
        not_start_icreated: 0,
        start_icreated: 0,
        finish_icreated: 0
      },
      allotList: [],
      allotQuery: {
        issue_status: '1'
      },
      ICreateList: [],
      ICreateQuery: {
        issue_status: '1'
      }
    }
  },
  computed: {
    issue_status() {
      return this.cat.issue_bstatus.filter(e=> e.cat_code !== '10').sort((a, b) => {
        if (a.cat_name === '已完成') {
          return 1
        } else if (a.cat_name === '未开始' && b.cat_name !== '已完成') {
          return 1
        } else {
          return -1
        }
      })
    }
  },
  mounted() {
    this.getSta()
    this.getAllotList() // 分配给我的列表
    this.getICreateList() // 我创建的列表
  },
  methods: {
    getSta() {
      fetch('POST', '/prj/issue/sta/forme', {}).then((res) => {
        if (res.status === 0) {
          this.staData = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getAllotList() {
      fetch('POST', '/prj/issue/workitems/tome', this.allotQuery).then((res) => {
        if (res.status === 0) {
          this.allotList = res.data.datas
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getICreateList() {
      fetch('POST', '/prj/issue/workitems/icreated', this.ICreateQuery).then((res) => {
        if (res.status === 0) {
          this.ICreateList = res.data.datas
        } else {
          this.$message.error(res.message)
        }
      })
    },
    customRow(item) {
      return {
        on: {
          click: () => {
            if (item.issue_type === "T" || item.issue_type === "M") {
              this.$router.push({
                path: "/workspace/workitem",
                query: {
                  oid: item.oid,
                  detailType: item.issue_type
                }
              })
            }
          }
        }
      }
    }
  }
}
</script>
<style scoped lang="less">
.app-main-content {
  display: flex;
  padding: 12px 10px;

  .container-left, .container-right {
    width: 942px;
    flex-shrink: 0;

    &.container-left {
      margin-right: 12px;
    }

    .container-left-header, .container-right-header {
      height: 163px;
      background: #FFFFFF;
      overflow: hidden;

      .title {
        font-weight: 700;
        font-size: 18px;
        color: #060606;
        margin: 21px 13px 29px;
      }

      .content {
        display: flex;
        justify-content: center;

        .item {
          display: flex;
          align-items: center;
          padding: 0 81px 0 81px;
          box-sizing: border-box;

          &:nth-of-type(1){
            padding: 0 81px 0 0;
          }

          &:nth-of-type(2){
            border-left: 2px solid #F4F4F4;
            border-right: 2px solid #F4F4F4;
          }

          &:nth-of-type(3){
            padding: 0 0 0 81px;
          }

          img {
            width: 55px;
            height: 55px;
            display: block;
            margin-right: 21px;
          }

          .info {
            display: flex;
            flex-direction: column;
            align-items: center;

            .status {
              font-weight: 500;
              font-size: 16px;
              line-height: 16px;
              color: #929292;
            }

            .value {
              font-weight: 700;
              font-size: 42px;
              line-height: 42px;
              color: #4D4D4D;
            }
          }
        }
      }
    }

    .container-left-content, .container-right-content{
      width: 942px;
      height: 650px;
      margin-top: 12px;

     /deep/ .edp-card{
        height: 100%;
        box-shadow: none;

        .edp-card-body{
          overflow-y: auto;
          flex: 1;
          display: flex;
          flex-direction: column;

          .body-flex{
            flex: 1;
            display: flex;
            overflow: hidden;
            background-color: #F8F8F8;

            .body-left-table{
              flex: 1;
              height: 100%;
              overflow-y: auto;
              overflow-x: auto;
              background: #FFF;
              flex-shrink: 0;
              padding: 16px 12px 0 12px;


              .icon {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }
  }
}

</style>
